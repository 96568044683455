@import '../styles/variables.css';

/* HEADER PRINCIPAL */
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--nav-bg);
    padding: var(--spacing-md) var(--spacing-lg);
    box-shadow: var(--box-shadow-sm);
    z-index: 1000;
    transition: background-color var(--transition-slow), padding var(--transition-normal), box-shadow var(--transition-normal);
}

/* Mode scrolled : plus fluide */
.header.scrolled {
    background-color: var(--background-dark);
    padding: var(--spacing-sm) var(--spacing-lg);
    box-shadow: var(--box-shadow-md);
}

/* Conteneur principal du header */
.header-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: max-width var(--transition-normal);
}

/* LOGO */
.logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: var(--spacing-sm);
}

/* Gestion dynamique du logo */
.logo img {
    height: 60px; /* Taille par défaut */
    max-height: 80px;
    transition: height var(--transition-normal), opacity var(--transition-fast), transform var(--transition-normal);
}

/* Réduction du logo et remplacement en mode scrolled */
.scrolled .logo img {
    height: 50px;
    content: url('../assets/logo-light.png'); /* Remplace le logo */
    transform: scale(0.95);
}

/* Texte à côté du logo */
.logo-text {
    font-size: var(--font-size-lg);
    font-weight: 700;
    color: var(--text-primary);
    transition: color var(--transition-normal);
}

/* Mode scrolled : texte en blanc */
.scrolled .logo-text {
    color: var(--text-light);
}

/* NAVIGATION */
.nav-menu {
    display: flex;
    gap: var(--spacing-lg);
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    transition: all var(--transition-normal);
}

/* Liens de navigation */
.nav-link {
    color: var(--nav-text);
    text-decoration: none;
    font-weight: 500;
    padding: var(--spacing-sm);
    transition: color var(--transition-normal), transform var(--transition-normal);
    position: relative;
}

/* Couleur en mode scrolled */
.scrolled .nav-link {
    color: var(--text-light);
}

/* Hover et effet actif */
.nav-link:hover {
    color: var(--nav-text-hover);
    transform: translateY(-2px);
}

/* Effet souligné au hover */
.nav-link::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--nav-text-hover);
    transform: scaleX(0);
    transition: transform var(--transition-normal);
}

.nav-link:hover::after,
.nav-link.active::after {
    transform: scaleX(1);
}

/* BOUTON MENU BURGER (Responsive) */
.menu-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: var(--spacing-sm);
    color: var(--nav-text);
    font-size: 1.5rem;
    transition: color var(--transition-normal);
}

/* Changement du menu burger en mode scrolled */
.scrolled .menu-toggle {
    color: var(--text-light);
}

/* RESPONSIVE : Mobile */
@media (max-width: 768px) {
    .menu-toggle {
        display: block;
    }

    .nav-menu {
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        background-color: var(--background-secondary);
        flex-direction: column;
        padding: var(--spacing-lg);
        gap: var(--spacing-md);
        transform: translateY(-100%);
        opacity: 0;
        transition: all var(--transition-slow);
        pointer-events: none;
        border-radius: var(--border-radius-lg);
        box-shadow: var(--box-shadow-md);
    }

    .nav-menu.active {
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
    }

    .scrolled .nav-menu {
        background-color: var(--background-dark);
    }

    .nav-link {
        display: block;
        padding: var(--spacing-md);
        text-align: center;
        font-size: var(--font-size-lg);
    }

    /* Logo et texte sur une ligne en mode mobile */
    .logo {
        flex-direction: row;
    }
}

