@import '../styles/variables.css';

/* SECTION SERVICES */
.services-section {
    padding: var(--spacing-xxl) var(--spacing-md);
    background-color: var(--background-primary);
    overflow-x: hidden; /* Empêche l'overflow horizontal */
}

.services-container {
    max-width: 1200px;
    margin: 0 auto;
}

/* ONGLET DES CATÉGORIES - SCROLLABLE SUR MOBILE */
.service-tabs {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: var(--spacing-xl);
    gap: var(--spacing-md);
    overflow-x: auto; /* Permet de scroller horizontalement */
    scrollbar-width: thin;
    scrollbar-color: var(--accent-primary) transparent;
    padding: 0 var(--spacing-md); /* Ajoute un padding horizontal */
    -webkit-overflow-scrolling: touch; /* Pour un scroll plus fluide sur mobile */
    justify-content: flex-start; /* Aligne les onglets au début */
}


/* Masquer la scrollbar pour un rendu plus propre */
.service-tabs::-webkit-scrollbar {
    height: 6px;
}

.service-tabs::-webkit-scrollbar-thumb {
    background: var(--accent-primary);
    border-radius: var(--border-radius-md);
}

.service-tabs::-webkit-scrollbar-track {
    background: transparent;
}

.tab-button {
    padding: var(--spacing-md) var(--spacing-lg);
    background: transparent;
    border: 2px solid var(--accent-primary);
    border-radius: var(--border-radius-md);
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    color: var(--text-primary);
    transition: all var(--transition-normal);
    white-space: nowrap;
    flex-shrink: 0;
}

.tab-button:hover,
.tab-button.active {
    background-color: var(--accent-primary);
    color: var(--text-light);
    box-shadow: 0 0 15px rgba(92, 191, 201, 0.3);
}

/* GRILLE DES SERVICES */
.services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xl);
}

/* CARTE DE SERVICE */
.service-card {
    background: var(--background-secondary);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    text-align: center;
    transition: all var(--transition-normal);
    position: relative;
    overflow: hidden;
    border: 2px solid transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.service-card:hover {
    transform: translateY(-5px);
    border-color: var(--accent-primary);
    box-shadow: var(--box-shadow-lg);
}

/* ICÔNE DES SERVICES */
.service-icon {
    font-size: 3rem;
    margin-bottom: var(--spacing-md);
    animation: float 3s ease-in-out infinite;
}

/* BLOC DURÉE + BOUTON ALIGNÉ EN BAS */
.service-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
}

.service-duration {
    font-size: var(--font-size-md);
    font-weight: bold;
    color: var(--text-secondary);
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-md);
}

/* PRIX DU SERVICE */
.service-price {
    font-size: var(--font-size-md);
    font-weight: bold;
    color: var(--text-secondary);
    margin-bottom: var(--spacing-md);
}

/* BOUTON DE RÉSERVATION */
.service-cta {
    padding: var(--spacing-sm) var(--spacing-lg);
    background-color: var(--accent-primary);
    color: var(--text-light);
    border-radius: var(--border-radius-md);
    font-weight: 600;
    cursor: pointer;
    transition: all var(--transition-normal);
}

.service-cta:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(92, 191, 201, 0.3);
}

/* STYLE PAR DÉFAUT POUR LE NOM DU SERVICE */
.service-card h3 {
    font-size: 1.5rem;
    margin-bottom: var(--spacing-md);
}

/* STYLE POUR LES TITRES DE CATÉGORIE */
.category-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: var(--spacing-xl);
    text-align: left;
}

/* 🌍 RESPONSIVE : Optimisation pour mobile */
@media (max-width: 768px) {
    .services-section {
        padding: var(--spacing-xl) var(--spacing-md);
    }

    .services-grid {
        grid-template-columns: repeat(2, 1fr); /* Trois colonnes sur mobile */
        gap: var(--spacing-lg);
    }

    .service-card {
        padding: var(--spacing-lg);
    }

    .tab-button {
        padding: var(--spacing-sm) var(--spacing-md);
        font-size: var(--font-size-md);
    }

    .service-icon {
        font-size: 2.5rem;
    }

    .service-cta {
        width: 100%;
        text-align: center;
    }

    /* Réduction de la taille du nom du service sur mobile */
    .service-card h3 {
        font-size: 1rem;
        margin-bottom: var(--spacing-sm);
    }

    /* Style pour les titres de catégorie sur mobile */
    .category-title {
        font-size: 1.2rem;
        margin: var(--spacing-md) 0 var(--spacing-sm);
        text-align: left;
    }
}
