@import '../styles/variables.css';

/* SECTION CONTACT */
.contact-section {
    padding: var(--spacing-xxl) var(--spacing-md);
    background: var(--background-dark);
    color: var(--text-light);
}

.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-xl);
    align-items: center;
}

/* INFO CONTACT */
.contact-info {
    padding-right: var(--spacing-xl);
}

.contact-info h2 {
    color: var(--accent-primary);
    font-size: var(--font-size-xxl);
    margin-bottom: var(--spacing-md);
}

.contact-info p {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: var(--spacing-lg);
    font-size: var(--font-size-lg);
    line-height: 1.6;
}

/* MÉTHODES DE CONTACT */
.contact-methods {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
    margin-top: var(--spacing-xl);
}

.contact-method {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    padding: var(--spacing-md);
    background: rgba(255, 255, 255, 0.1);
    border-radius: var(--border-radius-md);
    transition: all var(--transition-normal);
}

.contact-method:hover {
    transform: translateX(10px);
    background: rgba(255, 255, 255, 0.2);
}

.method-icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--accent-primary);
    color: var(--text-light);
    border-radius: var(--border-radius-sm);
    font-size: 1.5rem;
}

.method-info h3 {
    color: var(--text-light);
    margin-bottom: var(--spacing-xs);
    font-size: var(--font-size-md);
}

.method-info p {
    color: rgba(255, 255, 255, 0.7);
    font-size: var(--font-size-sm);
    margin: 0;
}

/* FORMULAIRE */
.contact-form {
    background: var(--background-primary);
    padding: var(--spacing-xl);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--box-shadow-lg);
}

/* CHAMPS DE FORMULAIRE */
.form-group {
    margin-bottom: var(--spacing-md);
}

.form-label {
    display: block;
    margin-bottom: var(--spacing-xs);
    color: var(--text-primary);
    font-weight: 600;
}

.form-input,
.form-textarea {
    width: 100%;
    padding: var(--spacing-md);
    border: 2px solid transparent;
    border-radius: var(--border-radius-md);
    background: var(--text-light);
    color: var(--text-primary);
    font-size: var(--font-size-md);
    transition: all var(--transition-normal);
}

.form-input:focus,
.form-textarea:focus {
    border-color: var(--accent-primary);
    outline: none;
    box-shadow: 0 0 0 3px rgba(92, 191, 201, 0.3);
}

.form-textarea {
    min-height: 150px;
    resize: vertical;
}

/* BOUTON SOUMISSION */
.submit-button {
    width: 100%;
    padding: var(--spacing-md);
    background: var(--accent-primary);
    color: var(--text-light);
    border: none;
    border-radius: var(--border-radius-md);
    font-weight: 600;
    font-size: var(--font-size-md);
    cursor: pointer;
    transition: all var(--transition-normal);
}

.submit-button:hover {
    background: var(--text-light);
    color: var(--text-primary);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(92, 191, 201, 0.3);
}

.submit-button:active {
    transform: translateY(0);
}

/* RÉSEAUX SOCIAUX */
.social-links {
    display: flex;
    gap: var(--spacing-md);
    margin-top: var(--spacing-xl);
}

.social-link {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--background-primary);
    color: var(--accent-primary);
    border-radius: 50%;
    transition: all var(--transition-normal);
    font-size: 1.5rem;
}

.social-link:hover {
    background: var(--accent-primary);
    color: var(--text-light);
    transform: translateY(-5px);
}

/* 🌍 RESPONSIVE : Adaptation mobile */
@media (max-width: 768px) {
    .contact-container {
        grid-template-columns: 1fr;
        gap: var(--spacing-xl);
    }

    .contact-info {
        padding-right: 0;
        text-align: center;
    }

    .contact-methods {
        align-items: center;
    }

    .contact-method {
        width: 100%;
        text-align: left;
        padding: var(--spacing-md);
    }

    .contact-method:hover {
        transform: translateY(-5px);
    }

    .social-links {
        justify-content: center;
    }
}

