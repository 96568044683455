@import '../styles/variables.css';

/* GLOBAL */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-primary);
    color: var(--text-primary);
    line-height: 1.6;
}

/* CONTAINER PRINCIPAL */
.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

/* CONTENU PRINCIPAL */
main {
    flex: 1;
    padding-top: 60px;
    background-color: var(--background-primary);
}

/* TITRES */
h1, h2, h3, h4, h5, h6 {
    color: var(--text-primary);
    line-height: 1.2;
    margin-bottom: var(--spacing-md);
}

h1 {
    font-size: var(--font-size-hero);
    font-weight: bold;
    color:var(--text-dark);
}

h2 {
    font-size: var(--font-size-xxl);
    font-weight: bold;
    color: var(--brand-primary);
}

h3 {
    font-size: var(--font-size-xl);
    font-weight: 600;
}

p {
    margin-bottom: var(--spacing-md);
}

/* LIENS */
a {
    color: var(--brand-primary);
    text-decoration: none;
    transition: color var(--transition-normal);
}

a:hover {
    color: var(--accent-primary);
}

/* SECTIONS GLOBALES */
.section-container {
    background-color: var(--background-secondary);
    padding: var(--spacing-xxl) 0;
    border-radius: var(--border-radius-lg);
    box-shadow: var(--box-shadow-sm);
}

/* SECTION TESTIMONIALS */
.testimonial-section {
    background-color: var(--background-dark);
    color: var(--text-dark);
    padding: var(--spacing-xxl) 0;
    text-align: center;
    border-radius: var(--border-radius-lg);
    box-shadow: var(--box-shadow-md);
}

.testimonial-section h2 {
    color: var(--accent-primary);
}

/* BOUTONS */
button {
    cursor: pointer;
    font-family: inherit;
    border: none;
    outline: none;
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: var(--border-radius-md);
    background-color: var(--button-bg);
    color: var(--button-text);
    border: 2px solid var(--button-border);
    transition: all var(--transition-normal);
}

button:hover {
    background-color: var(--button-hover-bg);
    color: var(--button-hover-text);
    border-color: var(--button-hover-border);
}

/* FOOTER */
.footer {
    background-color: var(--footer-bg);
    color: var(--footer-text);
    padding: var(--spacing-lg);
    text-align: center;
}

/* RESPONSIVE */
@media (max-width: 768px) {
    h1 {
        font-size: var(--font-size-xxl);
    }

    h2 {
        font-size: var(--font-size-xl);
    }

    h3 {
        font-size: var(--font-size-lg);
    }

    .section-container,
    .testimonial-section {
        padding: var(--spacing-xl) 0;
    }
}

