@import '../styles/variables.css';

/* HERO PRINCIPAL */
.hero {
    position: relative;
    min-height: 85vh;
    /* background-image:url('https://images.caradisiac.com/images/0/6/0/9/210609/S0-210609-812554.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;*/
    background: var(--background-dark);
    color: var(--text-light);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: var(--spacing-xl) var(--spacing-md);
    box-shadow: var(--box-shadow-lg);
}

/* Overlay sombre pour améliorer la lisibilité */
.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--overlay-dark);
    z-index: 1;
}

/* CONTAINER DU HERO */
.hero-container {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    margin: 0 auto;
    padding: var(--spacing-xl) var(--spacing-lg);
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--spacing-xl);
}

@media (min-width: 768px) {
    .hero-container {
        grid-template-columns: 1.2fr 0.8fr;
        align-items: center;
    }
}

/* CONTENU DU HERO AVEC BACKGROUND TRANSPARENT */
.hero-content {
    position: relative;
    z-index: 2;
    max-width: 800px;
    animation: fadeIn 1s ease-out;
    background: rgba(0, 0, 0, 0.4); /* Fond transparent sombre */
    padding: var(--spacing-lg);
    border-radius: var(--border-radius-md);
    box-shadow: var(--box-shadow-md);
}

/* TITRE PRINCIPAL */
.hero h1 {
    font-size: var(--font-size-hero);
    margin-bottom: var(--spacing-lg);
    font-weight: 700;
    line-height: 1.2;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* SOUS-TITRE */
.hero-subtitle {
    font-size: 1.25rem;
    line-height: 1.6;
    opacity: 0.9;
}

/* PARAGRAPHE */
.hero p {
    font-size: var(--font-size-lg);
    margin-bottom: var(--spacing-xl);
    opacity: 0.9;
}

/* BANNIÈRE PROMO */
.promo-banner {
    background: rgba(92, 191, 201, 0.7); /* Couleur accent avec opacité */
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: var(--border-radius-md);
    font-size: var(--font-size-md);
    font-weight: 600;
    margin-bottom: var(--spacing-lg);
    animation: pulse 2s infinite;
    box-shadow: var(--box-shadow-sm);
}

/* CTA BUTTONS */
.hero-cta {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    align-items: center;
}

/* Bouton principal */
.cta-button {
    padding: var(--spacing-md) var(--spacing-xl);
    font-size: var(--font-size-lg);
    font-weight: 600;
    border: 2px solid var(--accent-primary);
    background-color: var(--accent-primary);
    color: var(--text-light);
    border-radius: var(--border-radius-md);
    cursor: pointer;
    transition: all var(--transition-normal);
    text-decoration: none;
    display: inline-block;
    box-shadow: var(--box-shadow-md);
}

.cta-button:hover {
    background-color: transparent;
    transform: scale(1.05);
    box-shadow: 0 0 20px var(--accent-primary);
}

/* Bouton secondaire */
.cta-secondary {
    background-color: transparent;
    border-color: var(--text-light);
}

.cta-secondary:hover {
    background-color: var(--text-light);
    color: var(--text-primary);
    border-color: var(--text-light);
}

/* SECTION HIGHLIGHTS */
.highlights-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: var(--spacing-lg);
}

/* CARTES DE SERVICE */
.service-highlight {
    background: rgba(255, 255, 255, 0.1);
    padding: var(--spacing-lg);
    border-radius: var(--border-radius-lg);
    text-align: center;
    transition: transform 0.3s ease, box-shadow var(--transition-normal);
    box-shadow: var(--box-shadow-sm);
}

.service-highlight:hover {
    transform: translateY(-5px);
    box-shadow: var(--box-shadow-md);
}

/* Icône des services */
.highlight-icon {
    font-size: 2.5rem;
    margin-bottom: var(--spacing-sm);
    display: block;
}

/* Titres et textes */
.service-highlight h3 {
    color: var(--text-light);
    margin: var(--spacing-sm) 0;
    font-size: 1.25rem;
}

.service-highlight p {
    opacity: 0.9;
    font-size: 1rem;
}

/* BADGE ÉCO RESPONSABLE */
.eco-badge {
    position: absolute;
    top: var(--spacing-lg);
    right: var(--spacing-lg);
    background: var(--accent-primary);
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    z-index: 3;
    box-shadow: var(--box-shadow-md);
}

.eco-icon {
    font-size: 1.25rem;
}

.eco-text {
    font-weight: 600;
    font-size: 0.9rem;
}

/* ANIMATIONS */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

/* RESPONSIVE */
@media (max-width: 768px) {
    .hero h1 {
        font-size: var(--font-size-xxl);
    }

    .hero p {
        font-size: var(--font-size-md);
    }

    .cta-button {
        width: 100%;
        max-width: 300px;
    }

    .hero h1 {
        font-size: 2.5rem;
    }

    .hero-subtitle {
        font-size: 1.1rem;
    }

    .eco-badge {
        top: var(--spacing-md);
        right: var(--spacing-md);
        padding: var(--spacing-xs) var(--spacing-sm);
    }

    /* Assurer que le contenu ne dépasse pas */
    .hero-content {
        padding: var(--spacing-lg);
        background: rgba(0, 0, 0, 0.6); /* Un peu plus foncé pour les petits écrans */
        border-radius: var(--border-radius-md);
    }
}

