@import '../styles/variables.css';

.footer {
    background: linear-gradient(135deg, var(--text-primary) 0%, var(--brand-primary) 100%);
    color: var(--text-light);
    padding: var(--spacing-xxl) var(--spacing-md) var(--spacing-xl);
    position: relative;
    overflow: hidden;
}

.footer-title{
    color: var(--background-primary);
}

.footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, var(--accent-primary) 0%, var(--brand-primary) 100%);
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
}

.footer-content {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    gap: var(--spacing-xl);
    margin-bottom: var(--spacing-xl);
}

.footer-info {
    max-width: 400px;
}

.footer-logo {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-lg);
}

.footer-logo img {
    height: 40px;
}

.footer-logo span {
    font-size: var(--font-size-xl);
    font-weight: 700;
    background: linear-gradient(135deg, var(--text-light) 0%, var(--accent-primary) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.footer-description {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: var(--spacing-lg);
    line-height: 1.6;
}

.footer-social {
    display: flex;
    gap: var(--spacing-md);
}

.social-icon {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    transition: all var(--transition-normal);
}

.social-icon:hover {
    background: var(--accent-primary);
    transform: translateY(-5px);
}

.footer-links h3 {
    color: var(--footer-title-color);
    font-size: var(--font-size-lg);
    margin-bottom: var(--spacing-lg);
    position: relative;
    padding-bottom: var(--spacing-sm);
}

.footer-links h3::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 2px;
    background: var(--accent-primary);
}

.footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-links li {
    margin-bottom: var(--spacing-sm);
}

.footer-links a {
    color: var(--footer-link-color);
    text-decoration: none;
    transition: all var(--transition-normal);
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-xs);
}

.footer-links a:hover {
    color: var(--text-light);
    transform: translateX(5px);
}

.footer-links a i {
    font-size: 0.8em;
}

.footer-newsletter h3 {
    color: var(--footer-title-color);
    font-size: var(--font-size-lg);
    margin-bottom: var(--spacing-lg);
}

.newsletter-form {
    display: flex;
    gap: var(--spacing-sm);
}

.newsletter-input {
    flex: 1;
    padding: var(--spacing-sm) var(--spacing-md);
    border: none;
    border-radius: var(--border-radius-md);
    background: rgba(255, 255, 255, 0.1);
    color: var(--text-light);
    transition: all var(--transition-normal);
}

.newsletter-input:focus {
    outline: none;
    background: rgba(255, 255, 255, 0.2);
}

.newsletter-button {
    padding: var(--spacing-sm) var(--spacing-md);
    background: var(--accent-primary);
    color: var(--text-light);
    border: none;
    border-radius: var(--border-radius-md);
    cursor: pointer;
    transition: all var(--transition-normal);
}

.newsletter-button:hover {
    background: var(--brand-primary);
    transform: translateY(-2px);
}

.footer-bottom {
    padding-top: var(--spacing-lg);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-copyright {
    color: rgba(255, 255, 255, 0.6);
    font-size: var(--font-size-sm);
}

.footer-legal {
    display: flex;
    gap: var(--spacing-lg);
}

.footer-legal a {
    color: var(--footer-link-color);
    text-decoration: none;
    font-size: var(--font-size-sm);
    transition: color var(--transition-normal);
}

.footer-legal a:hover {
    color: var(--text-light);
}

@media (max-width: 1024px) {
    .footer-content {
        grid-template-columns: 1fr 1fr;
        gap: var(--spacing-xl);
    }

    .footer-info {
        grid-column: 1 / -1;
        text-align: center;
        max-width: 600px;
        margin: 0 auto;
    }

    .footer-social {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .footer-content {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .footer-links h3::after {
        left: 50%;
        transform: translateX(-50%);
    }

    .footer-links a {
        justify-content: center;
    }

    .newsletter-form {
        flex-direction: column;
    }

    .footer-bottom {
        flex-direction: column;
        gap: var(--spacing-md);
        text-align: center;
    }

    .footer-legal {
        flex-direction: column;
        gap: var(--spacing-sm);
    }
}
