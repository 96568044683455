:root {
    /* 🎨 Couleurs principales */
    --background-primary: #E2E3DF; /* Gris clair */
    --background-secondary: #FFFFFF; /* Blanc */
    --text-primary: #1C1F2C; /* Noir bleuté */
    --brand-primary: #3A62A5; /* Bleu */
    --accent-primary: #5CBFC9; /* Cyan */

    /* 🌈 Couleurs secondaires */
    --text-secondary: #4A4A4A; /* Gris foncé */
    --text-light: #FFFFFF; /* Blanc */
    --overlay-dark: rgba(28, 31, 44, 0.7); /* Fond semi-transparent */

    /* 🌑 Mode sombre */
    --background-dark: #1C1F2C; /* Fond sombre */
    --background-dark-secondary: #2A2D3E; /* Gris foncé pour contrastes */
    --text-dark: #E2E3DF; /* Texte clair en mode sombre */

    /* 📏 Espacements */
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 32px;
    --spacing-xxl: 48px;

    /* 📦 Bordures et ombres */
    --border-radius-xs: 2px;
    --border-radius-sm: 4px;
    --border-radius-md: 8px;
    --border-radius-lg: 16px;
    --border-radius-xl: 24px;
    
    --box-shadow-xs: 0 1px 3px rgba(0, 0, 0, 0.08);
    --box-shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
    --box-shadow-md: 0 4px 8px rgba(0, 0, 0, 0.1);
    --box-shadow-lg: 0 8px 16px rgba(0, 0, 0, 0.1);
    --box-shadow-xl: 0 12px 24px rgba(0, 0, 0, 0.15);

    /* ⚡ Transitions */
    --transition-fast: 0.2s ease-in-out;
    --transition-normal: 0.3s ease-in-out;
    --transition-slow: 0.5s ease-in-out;

    /* 📝 Tailles de police */
    --font-size-xs: 0.75rem;
    --font-size-sm: 0.875rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.25rem;
    --font-size-xl: 1.5rem;
    --font-size-xxl: 2rem;
    --font-size-hero: 3rem;

    /* 🔘 Boutons */
    --button-bg: var(--brand-primary);
    --button-text: var(--text-light);
    --button-border: var(--accent-primary);
    --button-hover-bg: var(--accent-primary);
    --button-hover-text: var(--text-light);
    --button-hover-border: var(--text-light);

    /* 📌 Navigation */
    --nav-bg: var(--background-primary);
    --nav-text: var(--text-primary);
    --nav-text-hover: var(--accent-primary);
    --nav-active: var(--brand-primary);

    /* 📍 Footer */
    --footer-bg: var(--text-primary);
    --footer-text: var(--text-light);
    --footer-link-color: var(--accent-primary)!important;
    --footer-title-color: var(--text-secondary)!important;
}

