.booking-section {
    padding: 40px 20px;
    background-color: #f8f9fa;
    min-height: 100vh;
}

.booking-container {
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.booking-container h2 {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 30px;
    font-size: 2rem;
}

.booking-form {
    display: grid;
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form-group label {
    font-weight: 500;
    color: #2c3e50;
}

.form-group small {
    color: #666;
    font-size: 0.85rem;
}

.form-input,
.form-textarea,
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="time"],
select,
textarea {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.form-input:focus,
.form-textarea:focus,
input:focus,
select:focus,
textarea:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.time-slots {
    margin-top: 1.5rem;
}

.time-slots h3 {
    color: #2C3E50;
    margin-bottom: 1rem;
}

.slots-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 1rem;
}

.time-slot {
    padding: 0.75rem;
    background-color: #EDF2F7;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.time-slot:hover {
    background-color: #E2E8F0;
}

.time-slot.selected {
    background-color: #4299E1;
    color: white;
}

.submit-button {
    background-color: #4CAF50;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 6px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.submit-button:hover {
    background-color: #45a049;
}

.submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.error-message {
    color: #d32f2f;
    background-color: #ffebee;
    padding: 12px;
    border-radius: 6px;
    margin-top: 20px;
}

.success-message {
    text-align: center;
    padding: 30px;
    background-color: #e8f5e9;
    border-radius: 8px;
}

.success-message h3 {
    color: #2e7d32;
    margin-bottom: 15px;
}

.success-message p {
    color: #1b5e20;
    margin-bottom: 20px;
}

.return-home-button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.return-home-button:hover {
    background-color: #45a049;
}

.loading {
    text-align: center;
    color: #4A5568;
    padding: 1rem;
}

.error {
    color: #E53E3E;
    padding: 1rem;
    background-color: #FED7D7;
    border-radius: 4px;
    margin-top: 1rem;
}

@media (max-width: 768px) {
    .booking-container {
        padding: 20px;
    }

    .booking-form {
        gap: 15px;
    }

    .submit-button {
        padding: 12px 24px;
    }
    
    /* Adaptation du calendrier sur mobile */
    .fc {
        width: 100% !important;
        overflow-x: hidden;
        font-size: 0.8rem; /* Réduction de la taille de la police sur mobile */
    }
}
