@import '../styles/variables.css';

.faq-section {
    padding: var(--spacing-xxl) var(--spacing-md);
    background-color: var(--background-primary);
}

.faq-container {
    max-width: 800px;
    margin: 0 auto;
}

.faq-header {
    text-align: center;
    margin-bottom: var(--spacing-xl);
}

.faq-header h2 {
    color: var(--text-primary);
    font-size: var(--font-size-xxl);
    margin-bottom: var(--spacing-md);
}

.faq-header p {
    color: var(--text-secondary);
    font-size: var(--font-size-lg);
    max-width: 600px;
    margin: 0 auto;
}

.faq-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
}

.faq-item {
    background: var(--background-secondary);
    border: 2px solid rgba(92, 191, 201, 0.1);
    border-radius: var(--border-radius-lg);
    overflow: hidden;
    transition: all var(--transition-normal);
}

.faq-item:hover {
    border-color: var(--accent-primary);
    transform: translateX(5px);
}

.faq-question {
    padding: var(--spacing-lg);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border: none;
    width: 100%;
    text-align: left;
    color: var(--text-primary);
    font-weight: 600;
    font-size: var(--font-size-md);
    transition: all var(--transition-normal);
}

.faq-question:hover {
    color: var(--brand-primary);
}

.faq-icon {
    font-size: var(--font-size-xl);
    color: var(--accent-primary);
    transition: transform var(--transition-normal);
}

.faq-item.active .faq-icon {
    transform: rotate(45deg);
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: all var(--transition-normal);
    padding: 0 var(--spacing-lg);
    color: var(--text-secondary);
    line-height: 1.6;
}

.faq-item.active .faq-answer {
    max-height: 300px;
    padding: var(--spacing-lg);
    padding-top: 0;
}

.faq-category {
    display: inline-block;
    padding: var(--spacing-xs) var(--spacing-sm);
    background: rgba(92, 191, 201, 0.1);
    color: var(--accent-primary);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-sm);
    margin-right: var(--spacing-sm);
}

.contact-cta {
    text-align: center;
    margin-top: var(--spacing-xl);
    padding: var(--spacing-xl);
    background: linear-gradient(135deg, var(--brand-primary) 0%, var(--accent-primary) 100%);
    border-radius: var(--border-radius-lg);
    color: var(--text-light);
}

.contact-cta h3 {
    color: var(--text-light);
    font-size: var(--font-size-xl);
    margin-bottom: var(--spacing-md);
}

.contact-cta p {
    margin-bottom: var(--spacing-lg);
    opacity: 0.9;
}

.contact-button {
    display: inline-block;
    padding: var(--spacing-md) var(--spacing-xl);
    background: var(--text-light);
    color: var(--brand-primary);
    border-radius: var(--border-radius-md);
    font-weight: 600;
    text-decoration: none;
    transition: all var(--transition-normal);
}

.contact-button:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {
    .faq-container {
        padding: 0 var(--spacing-md);
    }

    .faq-question {
        font-size: var(--font-size-md);
        padding: var(--spacing-md);
    }

    .faq-answer {
        padding: 0 var(--spacing-md);
    }

    .faq-item.active .faq-answer {
        padding: var(--spacing-md);
        padding-top: 0;
    }
}
